<template>
  <div id="personBase">
    <CSTabBar
        :checkedTab="btnState"
        :tabbar="tabBarList"
        @changeTabBar="changeTabBar"
    ></CSTabBar>
    <!-- 点击资料管理时显示的页面 -->
    <template
        v-if="btnState === 0"
    >
      <div ref="folderInfoFilter" class="filter-panel">
        <CSSelect style="margin-right: 20px;">
          <select
              v-model="queryFolderInfo.createBy"
              style="width: 150px"
          >
            <option value="">全部创建人</option>
            <option
                v-for="staff in staffList"
                :key="staff.id"
                :value="staff.id"
            >
              {{ staff.name }}
            </option>
          </select>
        </CSSelect>
        <input
            v-model="queryFolderInfo.search"
            class="cs-input"
            placeholder="搜索资料夹名称"
            style="width: 180px;margin-left: 0; margin-right: 30px;"
            type="text"
        >
        <button
            class="btn btn-primary"
            type="button"
            @click="queryFolderBtn()"
        >查询
        </button>
      </div>
      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button
                  class="btn btn-primary sticky-right"
                  @click="lookAddFolder"
              >
                <svg
                    aria-hidden="true"
                    class="icon"
                >
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加资料夹
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>创建时间</th>
              <th>资料夹名称</th>
              <th>创建人</th>
              <th>查看权限</th>
              <th>资料</th>
              <th>状态</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr
                v-for="folder in folderList"
                :key="folder.folderId"
            >
              <td class="date-td">{{ folder.createTime }}</td>
              <td>{{ folder.name }}</td>
              <td>{{ folder.createBy + "【" + folder.jobTitle + "】" }}</td>
              <td>
                  <span
                      class="allow-click"
                      @click="lookJurisdiction(folder.folderId)"
                  >
                    查看
                  </span>
              </td>
              <td>
                  <span
                      class="allow-click"
                      @click="toLookData(folder.folderId, folder.userId)"
                  >
                    {{ folder.count == 0 ? "上传" : folder.count }}
                </span>
              </td>
              <td>{{ folder.isEnable }}</td>
              <td>
                <div
                    class="btn-group"
                >
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul
                      class="dropdown-menu"
                      style="min-width: 76px; width: 86px;"
                  >
                    <li
                        v-if="folder.isEnable !== '已启用'"
                        @click="FolderIsEnableBtn(folder.folderId, 1,  folder.userId)"
                    >
                      <a
                          style="width: 100%;"
                      >启用</a>
                    </li>
                    <li
                        v-else
                        @click="FolderIsEnableBtn(folder.folderId, 0, folder.userId)"
                    >
                      <a
                          style="width: 100%;"
                      >禁用</a>
                    </li>
                    <li
                        @click="editFolderBtn(folder, folder.userId)"
                    >
                      <a
                          style="width: 100%;"
                      >修改</a>
                    </li>
                    <li
                        @click="deleteFolderBtn(folder.folderId, folder.userId)"
                    >
                      <a
                          style="width: 100%;"
                      >删除</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <!-- 分页 -->
        <Pagination
            componentName="Pagination"
            name="pagination"
        ></Pagination>
      </div>
    </template>
    <!-- 点击查看记录时的页面 -->
    <template
        v-else
    >
      <div ref="recordFilter" class="filter-panel">
        <CSSelect i-width="36rpx" style="width: 185px; margin-right: 10px;">
          <el-date-picker
              v-model="ViewRecordsInfo.startTime"
              :editable="false"
              placeholder="请选择开始时间"
              prefix-icon="el-icon-time"
              style="width: 157px"
              type="date"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </CSSelect>
        <span
            class="inline-gray"
            style="margin: 0 0 15px 0;"
        >
        </span>
        <CSSelect i-width="36rpx" style="width: 185px;margin-right: 20px; margin-left: 10px;">
          <el-date-picker
              v-model="ViewRecordsInfo.endTime"
              :editable="false"
              placeholder="请选择结束时间"
              prefix-icon="el-icon-time"
              style="width: 157px"
              type="date"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </CSSelect>
        <CSSelect style="margin-right: 20px;">
          <select
              v-model="ViewRecordsInfo.userId"
              style="width: 150px"
          >
            <option value="">全部员工</option>
            <option
                v-for="staff in staffList"
                :key="staff.id"
                :value="staff.id">
              {{ staff.name }}
            </option>
          </select>
        </CSSelect>
        <input
            v-model="ViewRecordsInfo.search"
            class="cs-input"
            placeholder="搜索资料名称/资料夹名称/IP地址"
            style="width: 245px; margin-left: 0; margin-right: 30px;"
            type="text"
        >
        <button
            class="btn btn-primary"
            @click="getRecordList()"
        >
          查询
        </button>
      </div>
      <div class="result-panel">
        <CSTable :thead-top="recordFilterHeight">
          <template v-slot:thead>
            <tr>
              <th>查看时间</th>
              <th>员工</th>
              <th>资料</th>
              <th>所属资料夹</th>
              <th>IP地址</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr
                v-for="(record, index) in recordList"
                :key="index"
            >
              <td class="date-td">{{ record.accessLogTime }}</td>
              <td>{{ record.userName }}</td>
              <td>{{ record.fileName }}</td>
              <td>{{ record.folderName }}</td>
              <td>{{ record.ip }}</td>
            </tr>
          </template>
        </CSTable>
        <div>
          <!-- 分页 -->
          <Pagination
              componentName="Pagination"
              name="pagination"
          ></Pagination>
        </div>
      </div>
    </template>
    <!-- 添加资料夹对话框 -->
    <CSDialog
        :dialogTitle="dialogTitle"
        :dialogVisible="lookAddFolderVisible"
        dialogWidth="620px"
        @click="permissionTreeInfo.treePanelVisible = false"
        @onClose="lookAddFolderClose"
        @onConfirm="addFolderBtn"
    >
      <div
          slot="dialog-content"
          style="font-size: 15px; padding: 30px"
      >
        <div class="notice_box">
          <div class="notice_title">
            资料夹名称
          </div>
          <input
              v-model="createFolderParams.folderName"
              class="notice_name"
              maxlength="20"
              placeholder="限20个字"
              style="padding-left: 10px;"
              type="text"
          >
        </div>
        <div>
          <div class="notice_title">
            查看权限
          </div>
          <div
              style="display: inline-block; vertical-align: middle"
              @click.stop="permissionTreeInfo.treePanelVisible = !permissionTreeInfo.treePanelVisible"
          >
            <div class="tree-wrap" style="--height: 40px; --icon-max-width: 36px">
              <CSSelect
                  height="40px"
                  iWidth="36px"
                  style="width: 250px"
              >
                <div
                    v-if="createFolderParams.folderPermission.length > 0"
                    style="width: 200px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                >
                  <span
                      v-for="(Permission, roomIndex) in createFolderParams.folderPermission"
                      :key="roomIndex"
                      style="font-size: 24px; margin-left: 10px"
                  >
                    {{ handleJurisdiction(Permission) }}
                  </span>
                </div>
                <div
                    v-else
                    style="font-size: 24px; color: #999; margin-left: 10px"
                >
                  请选择职级
                </div>
              </CSSelect>
              <div v-if="permissionTreeInfo.treePanelVisible" class="tree-source-panel" style="width: 250px;"
                   @click.stop>
                <div v-for="(item, key) in JudgmentRendering()" :key="item.id" class="tree-item">
                  <div class="tree-item-name">
                    <div
                        v-if="item.children && item.children.length > 0"
                        :class="[
                          'glyphicon',
                          'glyphicon-triangle-right',
                          {
                            rotate: unfold[item.id],
                          },
                      ]"
                        @click="changeUnfold(item.id)"
                    ></div>
                    <input
                        :checked="item.checked"
                        :class="[
                        {
                          notAllChecked: !item.checked && hasChildChecked[item.id],
                          disabled: item.allowCheck != null && !item.allowCheck,
                        },
                      ]"
                        type="checkbox"
                        @change="changeChecked(item, key)"
                    />
                    <span>{{ item.name }}</span>
                  </div>
                  <template v-if="item.children && item.children.length > 0">
                    <div
                        v-show="unfold[item.id]"
                        class="tree-item-child hasCheckbox"
                    >
                      <div v-for="(childItem, key) in item.children" :key="childItem.id" class="tree-item">
                        <div class="tree-item-name">
                          <input
                              :checked="childItem.checked"
                              :class="[
                              {
                                notAllChecked: !childItem.checked && hasChildChecked[childItem.id],
                                disabled: childItem.allowCheck != null && !childItem.allowCheck,
                              },
                            ]"
                              type="checkbox"
                              @change="changeCheckedChild(item, childItem, key)"
                          />
                          <span>{{ childItem.name }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSDialog>
    <!-- 查看权限对话框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="lookJurisdictionVisible"
        dialog-header-class="alert-bg" dialogCancelBtnText="关闭"
        dialogTitle="查看权限"
        dialogWidth="460px"
        @onClose="lookJurisdictionVisible = false"
    >
      <div
          slot="dialog-content"
          style="padding: 30px 0 30px 30px; font-size: 24px"
      >
        <div
            style="overflow-y: auto; max-height: 150px;"
        >
          <div
              v-for="Permission in PermissionInfo"
              :key="Permission.dutyType"
              style="margin-bottom: 20px"
          >
            {{ handleJurisdiction(Permission) }}
          </div>
        </div>
      </div>
    </CSDialog>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from '@/components/common/CSDialog';
import Pagination from "@/components/Pagination";
import CSTabBar from "@/components/common/CSTabBar"
import dayjs from "dayjs";

import {DEPARTMENT_TYPES, JOB_LEVEL} from "@/constant";

import {
  createFolder,
  deleteFolderIsEnable,
  editFolder,
  queryAccessLog,
  queryAllFolder,
  queryDepartmentStaffUrl,
  selectPermission,
  updateFolderIsEnable,
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

export default {
  name: "PersonBase",
  components: {
    CSTable,
    CSSelect,
    CSDialog,
    Pagination,
    CSTabBar,
  },
  data() {
    return {
      tabBarList: {
        0: '资料管理',
        1: '查看记录'
      },
      recordFilterHeight: 0,
      filterHeight: 0,
      // 部门类型
      DEPARTMENT_TYPES,
      // 员工等级
      JOB_LEVEL,
      // 添加资料夹对话框显隐
      lookAddFolderVisible: false,
      // 查看权限对话框显隐
      lookJurisdictionVisible: false,
      // 员工列表
      staffList: [],
      // 文件夹列表
      folderList: [],
      // 记录列表
      recordList: [],
      // 权限信息
      PermissionInfo: [],
      // 用户信息
      userInfo: this.$vc.getCurrentStaffInfo(),
      // 切换按钮状态
      btnState: 0,
      // 对话框标题
      dialogTitle: "",
      // 查询文件夹请求体
      queryFolderInfo: {
        createBy: "",          //创建人
        search: "",            //搜索内容
        curPage: 1,           // 分页页码
        pageSize: 10,         //页容量   必填
      },
      // 创建文件夹请求体
      createFolderParams: {
        folderName: "",                                  //文件夹名 必填
        folderPermission: [],                            //文件权限 必填
        regionCode: this.$vc.getCurrentRegion().code,   //园区编码
      },
      // 查看记录请求体
      ViewRecordsInfo: {
        userId: "",              //用户名
        startTime: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
        endTime: dayjs().format("YYYY-MM-DD"),
        search: "",                //搜索内容
        curPage: 1,               //页码
        pageSize: 10,             //页容量 必填
      },
      // 权限树信息
      permissionTreeInfo: {
        treePanelVisible: false,
      },
      // 权限树
      permissionTree: [
        {
          id: 1002,
          name: "经理办公室",
          checked: false,
          jobLevel: {
            1: "主管",
            4: "员工"
          },
          children: [
            {
              id: 1,
              name: "主管",
              checked: false,
            },
            {
              id: 4,
              name: "员工",
              checked: false,
            }
          ]
        },
        {
          id: 1003,
          name: "工程部",
          checked: false,
          jobLevel: {
            1: "主管",
            4: "员工"
          },
          children: [
            {
              id: 1,
              name: "主管",
              checked: false,
            },
            {
              id: 4,
              name: "员工",
              checked: false,
            }
          ]
        },
        {
          id: 1004,
          name: "客服部",
          checked: false,
          jobLevel: {
            1: "主管",
            4: "员工"
          },
          children: [
            {
              id: 1,
              name: "主管",
              checked: false,
            },
            {
              id: 4,
              name: "员工",
              checked: false,
            }
          ]
        },
        {
          id: 1005,
          name: "保洁部",
          checked: false,
          jobLevel: {
            1: "主管",
            4: "员工"
          },
          children: [
            {
              id: 1,
              name: "主管",
              checked: false,
            },
            {
              id: 4,
              name: "员工",
              checked: false,
            }
          ]
        },
        {
          id: 1006,
          name: "保安部",
          checked: false,
          jobLevel: {
            1: "主管",
            4: "员工"
          },
          children: [
            {
              id: 1,
              name: "主管",
              checked: false,
            },
            {
              id: 4,
              name: "员工",
              checked: false,
            }
          ]
        },
        {
          id: 1007,
          name: "人事部",
          checked: false,
          jobLevel: {
            1: "主管",
            4: "员工"
          },
          children: [
            {
              id: 1,
              name: "主管",
              checked: false,
            },
            {
              id: 4,
              name: "员工",
              checked: false,
            }
          ]
        },
        {
          id: 1008,
          name: "财务部",
          checked: false,
          jobLevel: {
            1: "主管",
            4: "员工"
          },
          children: [
            {
              id: 1,
              name: "主管",
              checked: false,
            },
            {
              id: 4,
              name: "员工",
              checked: false,
            }
          ]
        },
        {
          id: 1009,
          name: "运营部",
          checked: false,
          jobLevel: {
            1: "主管",
            4: "员工"
          },
          children: [
            {
              id: 1,
              name: "主管",
              checked: false,
            },
            {
              id: 4,
              name: "员工",
              checked: false,
            }
          ]
        },
        {
          id: 1010,
          name: "招商部",
          checked: false,
          jobLevel: {
            1: "主管",
            4: "员工"
          },
          children: [
            {
              id: 1,
              name: "主管",
              checked: false,
            },
            {
              id: 4,
              name: "员工",
              checked: false,
            }
          ]
        },
        {
          id: 1011,
          name: "品质管理",
          checked: false,
          jobLevel: {
            1: "主管",
            4: "员工"
          },
          children: [
            {
              id: 1,
              name: "主管",
              checked: false,
            },
            {
              id: 4,
              name: "员工",
              checked: false,
            }
          ]
        },
      ],
      unfold: {},
      hasChildChecked: {},
      // 修改的文件夹id
      editfolderId: "",
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    window.addEventListener("keydown", this.getListDown);
    // 获取资料管理列表
    this.getFolderList();
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getListDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.getListDown);
  },
  mounted() {
    const departmentCode = this.userInfo.departmentCode;
    // 获取员工列表
    this.getStaffList(departmentCode);
    this.$vc.on(this.$route.path, "pagination", "page_event", (page) => {
      this.pageParams.pageNo = page;
      if (this.btnState == 0) {
        this.getFolderList(page);
      } else {
        this.getRecordList(page);
      }
    });
  },
  updated() {
    const {folderInfoFilter, recordFilter} = this.$refs;
    if (folderInfoFilter) {
      this.filterHeight = folderInfoFilter.offsetHeight;
    }
    if (recordFilter) {
      this.recordFilterHeight = recordFilter.offsetHeight;
    }

    window.addEventListener('resize', () => {
      const {folderInfoFilter, recordFilter} = this.$refs;
      if (folderInfoFilter) {
        this.filterHeight = folderInfoFilter.offsetHeight;
      }
      if (recordFilter) {
        this.recordFilterHeight = recordFilter.offsetHeight;
      }
    })
  },
  methods: {
    // tab切换
    changeTabBar(type = 0) {
      this.btnState = type;
      if (type == 0) {
        // 获取资料管理列表
        this.getFolderList();
      } else {
        // 获取记录列表
        this.getRecordList();
      }
    },
    getListDown(e) {
      if (e.keyCode == 13) {
        if (this.btnState === 1) {
          this.queryFolderBtn();
        } else {
          this.getRecordList();
        }
      }
    },
    // 获取员工列表
    getStaffList(departmentCode) {
      this.$fly
          .post(queryDepartmentStaffUrl, {
            regionCode: this.$vc.getCurrentRegion().code || "",
            departmentCode,
            jobLevel: '',
            isOnTheJob: true,
          }).then(res => {
        if (res.code !== 0) {
          return
        }
        this.staffList = res.data;
      })
    },
    // 获取文件夹列表
    getFolderList(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.queryFolderInfo.curPage = pageNo;
      this.queryFolderInfo.pageSize = pageSize;
      this.$fly
          .post(queryAllFolder, {
            ...this.queryFolderInfo,
          })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              })
            }
            this.folderList = res.data.datas;
          })
    },
    // 获取记录列表
    getRecordList(pageNo = 1, pageSize = 10) {
      this.ViewRecordsInfo.curPage = pageNo;
      this.ViewRecordsInfo.pageSize = pageSize;
      this.$fly
          .post(queryAccessLog, this.ViewRecordsInfo)
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              })
            }
            this.recordList = res.data.datas;
          })
    },
    // 判断权限树渲染内容
    JudgmentRendering() {
      let dutyType = Number(this.userInfo.dutyType);
      let renderTree = [];
      if (dutyType == DEPARTMENT_TYPES.MANAGER || dutyType == DEPARTMENT_TYPES.PERSONNEL) {
        return this.permissionTree;
      } else {
        this.permissionTree.forEach((item, index) => {
          if (dutyType == item.id) {
            renderTree.push(item);
          }
        });
      }
      return renderTree;
    },
    // 查询按钮
    queryFolderBtn() {
      this.queryFolderInfo.curPage = 1;
      this.$fly
          .post(queryAllFolder, this.queryFolderInfo)
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.folderList = res.data.datas;
          })
    },
    // 打开添加资料夹
    lookAddFolder() {
      if (this.userInfo.dutyType != '1007' && this.userInfo.dutyType != '1002') {
        if (this.userInfo.jobLevel != 1) {
          this.$vc.toast("经理,主管或人事才可添加");
          return;
        }
      }
      this.dialogTitle = "添加资料夹"
      this.createFolderParams.folderName = "";
      this.lookAddFolderVisible = true;
      this.permissionTree.forEach(item => {
        item.checked = false;
        item.children.forEach(child => {
          child.checked = false;
        })
      });
    },
    // 添加/修改资料夹确认按钮
    addFolderBtn() {
      if (!this.createFolderParamsValidate()) {
        this.$vc.toast(this.$vc.validate.errInfo);
        return;
      }
      if (this.editfolderId != "") {
        this.$fly
            .post(editFolder, {
              ...this.createFolderParams,
              folderId: this.editfolderId
            })
            .then(res => {
              this.createFolderParams.folderPermission = [];
              if (res.code !== 0) {
                return;
              }
              this.lookAddFolderVisible = false;
              this.getFolderList();
            })
      } else {
        this.$fly
            .post(createFolder, this.createFolderParams)
            .then(res => {
              this.createFolderParams.folderPermission = [];
              if (res.code !== 0) {
                return;
              }
              this.lookAddFolderVisible = false;
              this.getFolderList();
            })
      }
    },
    // 关闭添加资料夹
    lookAddFolderClose() {
      this.lookAddFolderVisible = false;
      this.permissionTreeInfo.treePanelVisible = false;
      this.createFolderParams.folderPermission = [];
      this.createFolderParams.folderName = "";
      this.editfolderId = "";
      this.unfold = {};
    },
    // 启用或禁用
    FolderIsEnableBtn(folderId, enable, folderCreateId) {
      if (folderCreateId != this.userInfo.id) {
        this.$vc.toast("资料夹创建人可启用/禁用");
        return;
      }
      if (this.userInfo.dutyType != '1007' && this.userInfo.dutyType != '1002') {
        if (this.userInfo.jobLevel != 1) {
          this.$vc.toast("经理,主管或人事才可添加");
          return;
        }
      }
      this.$fly
          .post(updateFolderIsEnable, {
            folderId,
            enable
          })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.getFolderList();
          })
    },
    // 获取权限
    async getSelectPermission(folderId) {
      let res = await this.$fly.get(selectPermission, {
        folderId
      })
      if (res.code !== 0) {
        return;
      }
      this.PermissionInfo = res.data;
    },
    // 修改文件夹
    editFolderBtn(folder, folderCreateId) {
      if (folderCreateId != this.userInfo.id) {
        this.$vc.toast("资料夹创建人可修改");
        return;
      }
      if (this.userInfo.dutyType != '1007' && this.userInfo.dutyType != '1002') {
        if (this.userInfo.jobLevel != 1) {
          this.$vc.toast("经理,主管或人事才可添加");
          return;
        }
      }
      this.dialogTitle = "修改资料夹"
      this.editfolderId = folder.folderId;
      this.createFolderParams.folderName = folder.name;
      this.permissionTree.forEach(item => {
        item.checked = false;
        item.children.forEach(child => {
          child.checked = false;
        })
      });
      this.$fly
          .get(selectPermission, {
            folderId: folder.folderId
          })
          .then(res => {
            if (res.code != 0) {
              return;
            }
            res.data.forEach(item => {
              item.dutyType = Number(item.dutyType)
            });
            this.PermissionInfo = res.data;
            this.createFolderParams.folderPermission = res.data;
            this.PermissionInfo.forEach(jurisdictionItem => {
              let dutyType = Number(jurisdictionItem.dutyType);
              this.permissionTree.forEach(treeItem => {
                if (dutyType == treeItem.id) {
                  treeItem.checked = true;
                  if (jurisdictionItem.jobLevel.length == 2) {
                    treeItem.children[0].checked = true;
                    treeItem.children[1].checked = true;
                  } else if (jurisdictionItem.jobLevel[0] == 1) {
                    treeItem.children[0].checked = true;
                  } else if (jurisdictionItem.jobLevel[0] == 4) {
                    treeItem.children[1].checked = true;
                  }
                }
              })
            })
          })
      this.lookAddFolderVisible = true;
    },
    // 删除文件夹
    deleteFolderBtn(folderId, folderCreateId) {
      if (folderCreateId != this.userInfo.id) {
        this.$vc.toast("资料夹创建人可删除");
        return;
      }
      if (this.userInfo.dutyType != '1007' && this.userInfo.dutyType != '1002') {
        if (this.userInfo.jobLevel != 1) {
          this.$vc.toast("经理,主管或人事才可添加");
          return;
        }
      }
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定删除吗?",
        onConfirm: () => {
          this.$fly
              .post(deleteFolderIsEnable, {
                folderId,
              })
              .then(res => {
                if (res.code !== 0) {
                  return;
                }
                this.$vc.toast("删除成功");
                this.$CSDialog.instance.closeDialog();
                this.getFolderList();
              });
        }
      })
    },
    // 查看查看权限
    lookJurisdiction(folderId) {
      this.getSelectPermission(folderId);
      this.lookJurisdictionVisible = true;
    },
    // 去二级页面
    toLookData(folderId, folderCreateId) {
      this.$router.push({path: "lookData", query: {folderId, folderCreateId}})
    },
    // 更改展开状态
    changeUnfold(id) {
      this.$set(this.unfold, id, !this.unfold[id]);
    },
    // 处理权限
    handleJurisdiction(Permission) {
      let dutyType = Number(Permission.dutyType);
      let name = "";
      this.permissionTree.forEach(item => {
        if (item.id == dutyType && Permission.jobLevel.length == 2) {
          name = item.name + ": 主管、员工";
          return;
        } else if (item.id == dutyType && Permission.jobLevel[0] == 1) {
          name = item.name + ": 主管";
          return;
        } else if (item.id == dutyType && Permission.jobLevel[0] == 4) {
          name = item.name + ": 员工";
          return;
        }
      })
      return name;
    },
    // 处理父级
    changeChecked(item, key) {
      let dutyTypes = [];
      this.createFolderParams.folderPermission.forEach(item => {
        dutyTypes.push(item.dutyType);
      })
      if (!dutyTypes.includes(item.id)) {
        item.checked = true;
        let jurisdiction = {
          dutyType: item.id,
          jobLevel: [1, 4]
        }
        this.permissionTree.forEach(dutyTypeObj => {
          if (dutyTypeObj.id == item.id) {
            dutyTypeObj.children.forEach(item => {
              item.checked = true;
            })
          }
        })
        this.createFolderParams.folderPermission.push(jurisdiction);
      } else {
        let index = dutyTypes.indexOf(item.id);
        this.createFolderParams.folderPermission.splice(index, 1);
        item.checked = false;
        this.permissionTree.forEach(dutyTypeObj => {
          if (dutyTypeObj.id == item.id) {
            dutyTypeObj.children.forEach(item => {
              item.checked = false;
            })
          }
        })
      }
    },
    // 处理子级
    changeCheckedChild(item, child, key) {
      let dutyTypes = [];
      this.createFolderParams.folderPermission.forEach(dutyTypeObj => {
        dutyTypes.push(dutyTypeObj.dutyType);
      })
      if (dutyTypes.includes(item.id)) {
        let index = dutyTypes.indexOf(item.id);
        if (!this.createFolderParams.folderPermission[index].jobLevel.includes(child.id)) {
          this.createFolderParams.folderPermission[index].jobLevel.push(child.id);
          child.checked = true;
        } else {
          let levelIndex = this.createFolderParams.folderPermission[index].jobLevel.indexOf(child.id);
          this.createFolderParams.folderPermission[index].jobLevel.splice(levelIndex, 1);
          if (this.createFolderParams.folderPermission[index].jobLevel.length < 1) {
            this.createFolderParams.folderPermission.splice(index, 1);
            item.checked = false;
          }
          child.checked = false;
        }
      } else {
        let jurisdiction = {
          dutyType: item.id,
          jobLevel: [child.id]
        }
        child.checked = true;
        this.createFolderParams.folderPermission.push(jurisdiction);
        item.checked = true;
      }
    },
    // 表单校验
    createFolderParamsValidate() {
      return this.$vc.validate.validate(
          {
            createFolderParams: this.createFolderParams,
          },
          {
            "createFolderParams.folderName": [
              {
                limit: "required",
                param: "",
                errInfo: "文件夹名称不能为空",
              }
            ],
            "createFolderParams.folderPermission": [
              {
                limit: "required",
                param: "",
                errInfo: "查看权限不能为空",
              }
            ]
          }
      )
    }
  },
}
</script>

<style lang="stylus" scoped>
.notice_box
  margin-bottom 23px

.notice_name
  width 400px
  height 40px
  font-size 24px
  border 1px solid #999
  border-radius 4px
  vertical-align middle

.notice_title
  display inline-block
  font-size 22px
  width 120px
  color #000
  text-align right
  margin-right 40px
  vertical-align middle

.tree-wrap
  max-width 100%
  position relative

  .cs-input
    width 215px

.tree-source-panel
  min-height 60px
  max-height 320px
  overflow-y auto
  background #FFFFFF
  z-index 1
  padding 10px
  box-shadow 0 0 10px 0 rgba(0, 0, 0, 0.15)
  border-radius 10px
  position absolute
  right 0
  top calc(var(--height, 40px) + 10px)

.tree-item
  &-child
    padding-left 20px

    &.hasCheckbox
      padding-left 50px

  &-name
    font-size 24px

    input
      vertical-align middle
      margin-right 8px

      &[type='checkbox']
        &.disabled
          &::after
            position absolute
            width 20px
            height 20px
            top 0
            background-image url("../../../public/common/static/img/checkbox/disabledChecked.png");
            background-size 105% 105%;
            background-position center;
            display inline-block
            border-radius 3px
            border 1px solid #979797
            cursor no-drop

    span
      vertical-align middle

  .glyphicon.glyphicon-triangle-right
    font-size 10px
    margin-right 8px
    vertical-align middle

  .glyphicon.glyphicon-triangle-right.rotate
    -webkit-animation arrowRotate 0.1s linear 1
    animation arrowRotate 0.1s linear 1
    transform rotate(90deg)

  @keyframes arrowRotate
    0%
      -webkit-transform rotate(0deg)
    50%
      -webkit-transform rotate(45deg)
    100%
      -webkit-transform rotate(90deg)
  @keyframes arrowRotate
    0%
      -webkit-transform rotate(0deg)
    50%
      -webkit-transform rotate(45deg)
    100%
      -webkit-transform rotate(90deg)

input::-webkit-input-placeholder
  color: #999;

input::-moz-placeholder
  color: #999;

input::-moz-placeholder
  color: #999;

input::-ms-input-placeholder
  color: #999;
</style>
